import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices

import usersReducer from "./slices/users";

import categoriesReducer from "./slices/categories";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const categoriesPersistConfig = {
  key: "categories",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const rootReducer = combineReducers({
  users: usersReducer,
  categories: persistReducer(categoriesPersistConfig, categoriesReducer),
});

export { rootPersistConfig, rootReducer };
