// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  login: `تسجيل الدخول`,
  email_is_required: `الايميل مطلوب`,
  password_is_required: `كلمة السر مطلوبة`,
  email_must_be_a_valid_email_address: `يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا`,
  phone_number: `رقم الهاتف`,
  password: `الرقم السري`,
  enter_phone_number: `ادخل رقم الهاتف`,
  enter_password: `ادخل الرقم السري`,
  en: `انكليزي`,
  ar: `عربي`,
  username: `اسم المستخدم`,
  front_page: `الصفحة الرئيسية`,
  banner: `شعار`,
  view_and_control: `العرض والتحكم`,
  team: `الفريق`,
  workflow: `سير العمل`,
  customers: `العملاء`,
  packages: `الحزم`,
  contact_us: `تواصل معنا`,
  users: `المستخدمين`,
  roles: `الأدوار`,
  meals: `الوجبات`,
  logs: `سجلات`,
  logout: `تسجيل الخروج`,
  type: `النوع`,
  name_ar: `الاسم باللغة العربية`,
  name_en: `الاسم باللغة الانكليزية`,
  add_new_meal: `انشاء وجبة جديدة`,
  edit_meal: `تعديل وجبة`,
  meal: `وجبة`,
  enter_name_ar: `ادخل الاسم باللغة العربية`,
  enter_name_en: `ادخل الاسم باللغة الانكليزية`,
  send: `ارسال`,
  categories: `الاصناف`,
  category: `صنف`,
  add_new_category: `اضافة صنف جديد`,
  edit_category: `تعديل صنف`,
  name: `الاسم`,
  enter_name: `ادخل الاسم`,
  add_package: `اضافة حزمة`,
  edit_package: `تعديل حزمة`,
  package: `حزمة`,
  image: `الصورة`,
  restaurant_choice: `اختيار المطعم`,
  enter_restaurant_choice: `ادخل اختيار المطعم`,
  max_carp: `العدد الاعظمي للكاربوهيدرات`,
  enter_max_carp: `ادخل العدد الاعظمي للكاربوهيدرات`,
  max_protin: `العدد الاعظمي للبروتين`,
  enter_max_protin: `ادخل العدد الاعظمي للبروتين`,
  salad_count: `عدد السلطات`,
  snack_count: `عدد السناكات`,
  price: `السعر`,
  enter_price: `ادخل السعر`,
  enter_snack_count: `ادخل عدد السناكات`,
  enter_salad_count: `ادخل عدد السلطات`,
  actions: `الخيارات`,
};

export default ar;
