import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
