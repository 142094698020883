import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET Categories
export const getCategoriesService = createAsyncThunk(
  "categories/getCategoriesService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getCategoriesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
